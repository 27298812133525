import React from 'react';
import { assetLink } from 'src/utils';
import { createUseStyles } from 'react-jss';
import { breakpoint, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import ContactForm from 'src/components/ContactForm';

const useStyles = createUseStyles({
  textContainer: {
    [breakpoint.up('md')]: {
      marginTop: '-3vh', // offset the vertical padding
    },
    [breakpoint.down('md')]: {
      // layout shifts to vertical stacking
      marginTop: spacing(5),
      marginBottom: spacing(8),
    },
    paddingRight: spacing(4),
    paddingLeft: spacing(4),
    maxWidth: 700,
  },
  root: {
    background: 'linear-gradient(105.37deg, #13266D 16.05%, #5FBCFF 97.02%)',
    position: 'relative',
    [breakpoint.down('sm')]: {
      paddingTop: 0,
    },
  },
  shape: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    mixBlendMode: 'overlay',
    pointerEvents: 'none',
    [breakpoint.mobile()]: {
      display: 'none',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      zIndex: 1,
      position: 'relative',
      width: '50%',
      [breakpoint.down('md')]: {
        width: '100%',
      },
    },
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  checkRow: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    display: 'inline-block',
    width: 20,
    height: 20,
    marginRight: spacing(2),
  },
  contactFormContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    padding: spacing(10),
    maxWidth: '40%',
    marginLeft: 'auto',
    [breakpoint.down('md')]: {
      maxWidth: '100%',
    },
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.root}>
      <img src={assetLink('landing-ui/background-shapes/2.svg')} alt="" className={classes.shape} />
      <NavbarSpacer />
      <div className={classes.row}>
        <div className={classes.textContainer}>
          <Typography variant="h1" color="white" className="mb-5">
            Let Our Experts
            <br />
            Build Your Website
          </Typography>
          <Typography variant="p1" componentOverride="h2" color="white" className="mb-4 desktop:mb-7">
            Chat with one of our designers to create a website that truly reflects your vision.
            Simply enter your name and phone number and we&apos;ll quickly get back to you.
          </Typography>
          <div className={classes.checkRow}>
            <img src={assetLink('CheckIcon.svg')} className={classes.checkIcon} alt="Check Icon" />
            <Typography variant="p2" color="white">
              Responsive design to look great on every device
            </Typography>
          </div>
          <div className={classes.checkRow}>
            <img src={assetLink('CheckIcon.svg')} className={classes.checkIcon} alt="Check Icon" />
            <Typography variant="p2" color="white">
              We&apos;ll custom build the website tailored to your needs
            </Typography>
          </div>
          <div className={classes.checkRow}>
            <img src={assetLink('CheckIcon.svg')} className={classes.checkIcon} alt="Check Icon" />
            <Typography variant="p2" color="white">
              Built to optimize your Google search rankings
            </Typography>
          </div>
        </div>
        <div className={classes.contactFormContainer}>
          <ContactForm ctaColorOverride="teal" />
        </div>
      </div>
    </SectionContainer>
  );
};

export default Hero;
