import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint } from 'src/theme';
import Button from '../core/Button';
import Input from '../core/Input';
import Typography from '../core/Typography';
import PhoneNumberField from '../PhoneNumberField';
import useContactForm from './utils';

const useStyles = createUseStyles({
  container: {
    height: 'auto',
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    marginRight: '20%',
    [breakpoint.down('md')]: {
      marginRight: 0,
    },
  },
  inputLabel: {
    textTransform: 'uppercase',
  },
  submitButton: {
    width: '100%',
  },
  disclaimer: {
    fontSize: pxToRem(11),
    fontStyle: 'italic',
  },
});

const contactPreferences = [
  'Phone',
  'Text',
  'Email',
];

interface Props {
  ctaColorOverride?: 'blue' | 'teal' | 'white';
}

const ContactForm: React.FC<Props> = ({
  ctaColorOverride,
}) => {
  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    isSubmitted,
  } = useContactForm();
  const classes = useStyles();
  return (
    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
      <div>
        <Input
          label="FIRST NAME"
          name="firstName"
          error={errors.firstName}
          value={data.firstName}
          onChange={handleChange('firstName')}
          inputProps={{
            placeholder: 'First Name',
          }}
        />
      </div>
      <div>
        <Input
          label="LAST NAME"
          name="lastName"
          error={errors.lastName}
          value={data.lastName}
          onChange={handleChange('lastName')}
          inputProps={{
            placeholder: 'Last Name',
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <Input
          label="EMAIL"
          name="email"
          error={errors.email}
          value={data.email}
          onChange={handleChange('email')}
          inputProps={{
            placeholder: 'you@example.com',
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <PhoneNumberField
          showError={false}
          label="PHONE NUMBER"
          name="phone"
          value={data.phone}
          onChange={handleChange('phone')}
          inputProps={{
            placeholder: '(000) 000-0000',
            autoComplete: 'tel',
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <Input
          label="HOW WOULD YOU LIKE US TO CONTACT YOU?"
          name="contactPreference"
          type="select"
          value={data.contactPreference}
          onChange={handleChange('contactPreference')}
        >
          <option value="" disabled>- Select preference -</option>
          {contactPreferences.map((pref) => <option key={pref}>{pref}</option>)}
        </Input>
      </div>
      <div className="sm:col-span-2">
        <Input
          label="WHAT WOULD YOU LIKE US TO DISCUSS?"
          name="message"
          type="textarea"
          error={errors.message}
          value={data.message}
          onChange={handleChange('message')}
          inputProps={{
            placeholder: 'Your message here',
            rows: 4,
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <Typography variant="p1" color="textSecondary" className={classes.disclaimer}>
          By submitting contact information on this website, you are
          consenting to receive calls, SMS, and emails from Bizwise. You
          certify that you are the owner of the contact information provided.
        </Typography>
      </div>
      <div className="sm:col-span-2">
        <Button className={classes.submitButton} variant="filled" color={ctaColorOverride ?? 'blue'} onClick={handleSubmit} disabled={isSubmitted}>
          {isSubmitted ? 'Submitted!' : 'Submit'}
        </Button>
      </div>
      {isSubmitted && (
        <div className="sm:col-span-2">
          <Typography variant="p3" color="blue">
            A Bizwise representative will follow up with you as soon as possible.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
