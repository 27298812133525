import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/HireAnExpert/Hero';
import PricingSection from 'src/sections/HireAnExpert/Pricing';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import ApiWrapper from 'src/components/ApiWrapper';
import CustomerSpotlightsSection from 'src/sections/HireAnExpert/CustomerSpotlights';
import BuiltSection from 'src/sections/HireAnExpert/Built';
import WhySection from 'src/sections/HireAnExpert/Why';

const HireAnExpertPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Hire an Expert" />
      <HeroSection />
      <CustomerSpotlightsSection />
      <PricingSection />
      <BuiltSection />
      <WhySection />
      <FaqSection types={[FaqTypes.PRICING]} />
    </Layout>
  </ApiWrapper>
);

export default HireAnExpertPage;
