import React from 'react';
import cx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { createUseStyles } from 'react-jss';
import Typography from 'src/components/core/Typography';
import theme, { spacing, breakpoint, pxToRem } from 'src/theme';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  title: {
    maxWidth: pxToRem(970),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  underline: {
    marginBottom: spacing(20),
    [breakpoint.down('md')]: {
      marginBottom: spacing(10),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      position: 'relative',
      width: '50%',
      [breakpoint.down('md')]: {
        width: '100% !important',
      },
    },
  },
  row1: {
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  row2: {
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  row3: {
    [breakpoint.down('md')]: {
      paddingTop: spacing(15),
      flexDirection: 'column',
    },
  },
  textContent1: {
    marginLeft: '5%',
    maxWidth: pxToRem(622),
    paddingLeft: pxToRem(20),
    [breakpoint.down('md')]: {
      margin: `${spacing(8)} 0`,
      paddingLeft: 0,
    },
    [breakpoint.down('md')]: {
      margin: `${spacing(14)} 0 ${spacing(8)} 0`,
    },
  },
  textContent2: {
    maxWidth: pxToRem(622),
    paddingRight: pxToRem(20),
    [breakpoint.down('md')]: {
      margin: `${spacing(10)} 0`,
      paddingRight: 0,
    },
    marginLeft: 'auto',
    [breakpoint.down('md')]: {
      marginLeft: 0,
    },
  },
  presentation: {
    height: 'calc(50vw * 0.6)',
    width: '50%',
    maxWidth: pxToRem(600),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [breakpoint.down('md')]: {
      height: 'calc(100vw * 0.7)',
    },
  },
  rectangle: {
    borderRadius: 20,
    width: '87%',
    height: 0,
    paddingBottom: '65%',
    transform: 'rotate(119deg)',
    position: 'absolute',
    right: 0,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${pxToRem(120)} 0`,
    [breakpoint.down('sm')]: {
      padding: `${pxToRem(80)} 0`,
    },
  },
  image: {
    position: 'relative',
    width: 600,
  },
});

const BuiltSection: React.FC = () => {
  const classes = useStyles();
  const {
    height: firstMediaHeight = '70%',
    ref: firstMediaRef,
  } = useResizeObserver<HTMLDivElement>();
  const eclipseHeight = typeof firstMediaHeight === 'string'
    ? firstMediaHeight
    : (`calc(${pxToRem(firstMediaHeight)} + ${pxToRem(100)})`);
  return (
    <SectionContainer
      style={{
        overflow: 'visible',
        paddingTop: spacing(20),
        paddingBottom: spacing(20),
      }}
    >
      <Typography
        variant="h2"
        color="blue"
        underline
        centered
        className={classes.title}
        underlineClassName={classes.underline}
      >
        How our Website Design Service works
      </Typography>
      <div className={cx(classes.row, classes.row1)}>
        <div className={classes.presentation}>
          <div style={{
            position: 'absolute',
            backgroundColor: '#FEB471',
            borderRadius: '50%',
            // This is a trick keep the height equal to the width
            width: eclipseHeight,
            height: 0,
            paddingBottom: eclipseHeight,
          }}
          />
          <div ref={firstMediaRef}>
            <img
              width={600}
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/sections/27.png"
              alt="Hire an expert"
            />
          </div>
        </div>
        <div className={classes.textContent1}>
          <Typography variant="h3" color="blue" style={{ marginBottom: spacing(4) }}>
            Sharing your vision
          </Typography>
          <Typography variant="p2" color="blackish">
            Talk to a designer over the phone and tell us your ideas and vision,
            then we&apos;ll help you choose the best design for your website.
            Send us your preferred text, logos and images — or choose from our huge library of
            professional photos — to make your website amazing.
          </Typography>
        </div>
      </div>
      <div className={cx(classes.row, classes.row2)}>
        <div className={classes.textContent2}>
          <Typography variant="h3" color="blue" style={{ marginTop: spacing(11), marginBottom: spacing(4) }}>
            Building it for you
          </Typography>
          <Typography variant="p2" color="blackish">
            Once we get your input, our team of expert designers get to work bringing your vision
            to life with your chosen content and images. We then send it back to you for
            review, and once we get your approval, we publish it and your business goes
            live online for the world to see.
          </Typography>
        </div>
        <div className={classes.imgContainer}>
          <div style={{ position: 'relative', maxWidth: pxToRem(600) }}>
            <div
              style={{
                backgroundColor: theme.palette.primary.TEAL,
              }}
              className={classes.rectangle}
            />
            <img
              width={600}
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/sections/28.png"
              alt="Hire an expert"
            />
          </div>
        </div>
      </div>
      <div className={cx(classes.row, classes.row3)}>
        <div className={classes.presentation}>
          <div style={{
            position: 'absolute',
            backgroundColor: '#4282FF',
            borderRadius: '50%',
            // This is a trick keep the height equal to the width
            width: eclipseHeight,
            height: 0,
            paddingBottom: eclipseHeight,
          }}
          />
          <div ref={firstMediaRef}>
            <img
              width={600}
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/sections/29.png"
              alt="Hire an expert"
            />
          </div>
        </div>
        <div className={classes.textContent1}>
          <Typography variant="h3" color="blue" style={{ marginBottom: spacing(4) }}>
            Keepin&apos; it fresh
          </Typography>
          <Typography variant="p2" color="blackish">
            When inspiration strikes and you want to make updates to your site,
            our team is here. We offer ongoing maintenance plans so your website
            stays relevant as your business grows.
          </Typography>
        </div>
      </div>
    </SectionContainer>
  );
};

export default BuiltSection;
