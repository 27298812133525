import { useCallback, useState } from 'react';
import emailValidator from 'email-validator';

interface ContactFormData {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  contactPreference: string,
  message: string,
}

type ErrorKeys = 'firstName' | 'lastName' | 'email' | 'message'

type Errors = Partial<Record<ErrorKeys, string>>

interface ErrorValidation {
  errorKey: ErrorKeys,
  validate: () => boolean,
  errorMessage: string,
}

const emptyData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  contactPreference: '',
  message: '',
};

function useContactForm() {
  const [data, setData] = useState<ContactFormData>(emptyData);
  const [errors, setErrors] = useState<Errors>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = useCallback((key: keyof ContactFormData) => (
    (val: string) => {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [key]: undefined,
      }));
      setData((oldData) => ({
        ...oldData,
        [key]: val,
      }));
    }
  ), []);

  const handleSubmit = useCallback(async () => {
    const {
      firstName,
      lastName,
      email,
      message,
    } = data;

    const validations: ErrorValidation[] = [
      {
        errorKey: 'email',
        validate: () => !!(email && emailValidator.validate(email)),
        errorMessage: 'Please enter a valid email address!',
      },
      {
        errorKey: 'firstName',
        validate: () => !!firstName,
        errorMessage: 'A first name is required!',
      },
      {
        errorKey: 'lastName',
        validate: () => !!lastName,
        errorMessage: 'A last name is required!',
      },
      {
        errorKey: 'message',
        validate: () => !!message,
        errorMessage: 'A message is required!',
      },
    ];

    const newErrors: Errors = {};
    validations.forEach(({ errorKey, validate, errorMessage }) => {
      if (!validate()) {
        newErrors[errorKey] = errorMessage;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setIsSubmitted(true);
    try {
      await window.apis['home-api'].sales.contactSales({
        data,
      });
      setData(emptyData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [data, setData]);

  return {
    data,
    errors,
    handleChange,
    handleSubmit,
    isSubmitted,
  };
}

export default useContactForm;
