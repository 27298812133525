import React, { useState, useEffect, useRef } from 'react';
import { useIsVisible } from 'src/hooks';
import LaptopContainer from 'src/images/video-containers/BlackMac.svg';

interface Props {
  videoSrc?: string;
  poster?: string;
}

const CustomerSpotlightAutoplayingVideo: React.FC<Props> = ({
  videoSrc,
  poster,
}) => {
  const [videoReady, setMacVideoReady] = useState(false);
  const [videoEnded, setMacVideoEnded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVisible = useIsVisible(videoRef);

  // iOS workaround to force the video to load
  useEffect(() => {
    videoRef.current?.load();
  }, [videoRef]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState > 2) {
      setMacVideoReady(true);
    }
  }, []);

  useEffect(() => {
    if (isVisible && videoReady && videoRef.current) {
      videoRef.current.play();
    }
  }, [isVisible, videoReady]);

  useEffect(() => {
    if (videoEnded) {
      setMacVideoEnded(false);
      videoRef.current?.play();
    }
  }, [videoEnded]);

  return (
    <div style={{ position: 'relative' }}>
      <video
        controls={false}
        disableRemotePlayback
        ref={videoRef}
        src={videoSrc}
        poster={poster}
        muted
        playsInline
        onCanPlayThrough={() => setMacVideoReady(true)}
        onEnded={() => setMacVideoEnded(true)}
        style={{
          position: 'absolute',
          top: '6%',
          left: '10%',
          height: '80%',
          width: '80%',
          objectFit: 'fill',
        }}
      />
      <LaptopContainer style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default CustomerSpotlightAutoplayingVideo;
