import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export type Spotlight = {
  businessName: string,
  description: string,
  img: React.ReactNode,
  poster?: string,
  videoSrc?: string,
}

const assetBase = 'https://assets.bizwise.com/landing-ui/videos/customer-sites';
const imgWidth = 780;
const className = 'w-full';

const spotlights: Spotlight[] = [
  {
    businessName: 'Cherrys Propane | Propane Installations',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/cherryspropane.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Cherrys Propane Screenshot" className={className} src={`${assetBase}/cherryspropane.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/cherryspropane.com.mp4`,
  },
  {
    businessName: 'TBX Remodeling & Renovations | Remodeling and Renovations',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/remodelwithtbx.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="TBX Remodeling Screenshot" className={className} src={`${assetBase}/remodelwithtbx.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/remodelwithtbx.com.mp4`,
  },
  {
    businessName: 'Nancy Baird PsyD | Licensed Psychologist',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/bairdpsychologyservices.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Nancy Baird PsyD Screenshot" className={className} src={`${assetBase}/bairdpsychologyservices.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/bairdpsychologyservices.com.mp4`,
  },
  {
    businessName: 'Transport Management Group | Hazmat Training',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/tmgihazmat.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Transport Management Group Screenshot" className={className} src={`${assetBase}/tmgihazmat.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/tmgihazmat.com.mp4`,
  },
  {
    businessName: 'Sweet Potato Pie | Bluegrass Band',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/sweet-potato-pie.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Sweet Potato Pie Screenshot" className={className} src={`${assetBase}/sweet-potato-pie.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/sweet-potato-pie.com.mp4`,
  },
  {
    businessName: 'Great Renovations Inc | Window & Door Renovations',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/grenovations.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Great Renovations Inc Screenshot" className={className} src={`${assetBase}/grenovations.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/grenovations.com.mp4`,
  },
  {
    businessName: 'Alert Fire Protection | Safety Products Supplier',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    img: <StaticImage width={imgWidth} alt="Alert Fire Protection Screenshot" className={className} src="https://assets.bizwise.com/landing-ui/customers/alertfire.png" />,
  },
  {
    businessName: 'Service by Isabel | Janitorial Services',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/servicebyisabel.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Service by Isabel Screenshot" className={className} src={`${assetBase}/servicebyisabel.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/servicebyisabel.com.mp4`,
  },
  {
    businessName: 'Apex Foam Insulation LLC | Foam Insulation',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/iamfoamking.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Perceptive Polygraph Screenshot" className={className} src={`${assetBase}/iamfoamking.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/iamfoamking.com.mp4`,
  },
  {
    businessName: 'VapeHead USA | Vape Shops',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/vapeheadusa.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="VapeHead USA Screenshot" className={className} src={`${assetBase}/vapeheadusa.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/vapeheadusa.com.mp4`,
  },
  {
    businessName: 'Bothwin Capital | Private Lending Firm',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/bothwincapital.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Bothwin Capital Screenshot" className={className} src={`${assetBase}/bothwincapital.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/bothwincapital.com.mp4`,
  },
  {
    businessName: 'Mary\'s Roofing | Roofing Contractor',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
    poster: `${assetBase}/marysroofing.com.thumbnail.jpeg`,
    img: <StaticImage width={imgWidth} alt="Mary's Roofing Screenshot" className={className} src={`${assetBase}/marysroofing.com.thumbnail.jpeg`} />,
    videoSrc: `${assetBase}/marysroofing.com.mp4`,
  },
];

export default spotlights;
