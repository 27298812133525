import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import {
  spacing, theme, Theme, pxToRem, breakpoint,
} from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import IconButton from 'src/components/core/IconButton';
import ScreenshotCarousel from 'src/components/ScreenshotCarousel';
import Typography from 'src/components/core/Typography';
import spotlights from 'src/sections/Customers/spotlights';
import CustomerSpotlightAutoplayingVideo from 'src/components/CustomerSpotlightAutoplayingVideo';

const spotlightsWithVideos = spotlights.filter((s) => s.videoSrc && s.poster);

const useStyles = createUseStyles<string>(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: spacing(16),
    paddingBottom: spacing(16),
  },
  screenshotContainer: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoint.down('sm')]: {
      flexDirection: 'column',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.secondary.LIGHT_BLUE,
    padding: spacing(4),
    borderRadius: spacing(2),
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  carouselContainer: {
    marginTop: spacing(10),
  },
}));

const CustomerSpotlightsSection: React.FC = () => {
  const theme: Theme = useTheme();
  const classes = useStyles();
  const [activeIdx, setActiveIdx] = React.useState(0);
  return (
    <SectionContainer
      className={classes.container}
      background="light-blue"
      backgroundShapeBlendMode="soft-light"
    >
      <Typography
        variant="h2"
        color="blue"
        underline
        underlineClassName={classes.underline}
      >
        Websites designed and built by Bizwise
      </Typography>
      <Typography variant="p1" color="blue">
        We help small businesses to get their website online in less than 5 minutes.
      </Typography>
      <div className={classes.carouselContainer}>
        <ScreenshotCarousel
          activeIdx={activeIdx}
          setActiveIdx={setActiveIdx}
          isLight
        >
          {spotlightsWithVideos.map((spotlight) => (
            <CustomerSpotlightAutoplayingVideo
              videoSrc={spotlight.videoSrc}
              poster={spotlight.poster}
            />
          ))}
        </ScreenshotCarousel>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: pxToRem(30),
          justifyContent: 'center',
        }}
      >
        {Array.from({ length: spotlightsWithVideos.length }, (_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <IconButton onClick={() => setActiveIdx(i)} key={i}>
            <div
              style={{
                width: 9,
                height: 9,
                borderRadius: '50%',
                backgroundColor: activeIdx === i
                  ? theme.palette.primary.TEAL
                  : theme.palette.secondary.GRAY,
              }}
            />
          </IconButton>
        ))}
      </div>
    </SectionContainer>
  );
};

export default CustomerSpotlightsSection;
