import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  pxToRem, spacing, useIsMobile,
} from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';

const useStyles = createUseStyles({
  icon: {
    marginRight: spacing(7.5),
    width: pxToRem(80),
  },
  description: {
    marginTop: spacing(3),
    marginBottom: spacing(6),
    textAlign: 'center',
  },
  reason: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const reasons: {
  title: string,
  description: string,
  iconSrc: string,
}[] = [
  {
    title: 'Our industry expertise',
    description: 'Our design experts have 20+ years of expertise building custom websites to help small businesses reach their customers.',
    iconSrc: assetLink('landing-ui/icons/ExpertIcon.svg'),
  },
  {
    title: 'Your website goes live online — fast',
    description: 'Your time is valuable, so you won’t have to wait months to get your website. Our team of designers will work quickly behind the scenes to get it done so you can stay focused on running your business.',
    iconSrc: assetLink('landing-ui/icons/FastIcon.svg'),
  },
  {
    title: 'You get 24/7 support',
    description: 'Your web specialist will guide you through the process every step of the way, while 24/7 support ensures you never feel stuck.',
    iconSrc: assetLink('landing-ui/icons/24HourIcon.svg'),
  },
  {
    title: 'No hidden fees',
    description: 'Pay a one-time fee for the initial build, then an annual hosting fee. No surprises.',
    iconSrc: assetLink('landing-ui/icons/NoHiddenFeesIcon.svg'),
  },
];

const WhySection: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer backgroundShape={4} background="light-blue">
      <Typography variant="h2" color="blue" centered underline>
        4 reasons why you&apos;ll love your Bizwise website
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-12 mt-10 md:mt-10">
        {reasons.map(({
          title, description, iconSrc,
        }) => (
          <div className={classes.reason} key={title}>
            <img src={iconSrc} alt={`${title} Icon`} className={classes.icon} />
            <Typography variant="h3" color="blue">
              {title}
            </Typography>
            <Typography variant="p2" color="blackish" className={classes.description}>
              {description}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default WhySection;
